import React from "react";
import { Link } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

import { updateAPNContribution } from "./graphql/mutations";

import { yearOptions } from "./YearOptions";
import { statusOptions } from "./StatusOptions";
import { adminRegionOptions } from "./CountriesReference";

import EditContribution from "./EditContribution";

import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  SORTABLE_COLUMNS,
} from "./contributions/admin-table-config";
import { paginationLabels, distributionSelectionLabels } from "./common/labels";
import {
  Button,
  Table,
  TableFiltering,
  TablePagination,
  TableSelection,
  TableSorting,
  Flash,
  Modal,
  Textarea,
  Spinner,
  Select,
} from "@amzn/awsui-components-react";
import { getFilterCounterText } from "./common/tableCounterStrings";
import "./styles/table.scss";
import { createPropertyStorage } from "./common/createPropertyStorage";
import * as localBStorage from "./common/localStorage";
import {
  mapWithColumnDefinitionIds,
  addToColumnDefinitions,
} from "./common/columnDefinitionsHelper";

var floatRightStyle = {
  float: "right",
};

const widthsStorage = createPropertyStorage(
  "React-Table-Widths",
  localBStorage
);
const saveWidths = (e) =>
  widthsStorage.save(
    mapWithColumnDefinitionIds(COLUMN_DEFINITIONS, "width", e.detail.widths)
  );
const loadWidths = () =>
  widthsStorage
    .load()
    .then((columns) =>
      addToColumnDefinitions(COLUMN_DEFINITIONS, "width", columns)
    );

export const TableHeader = ({
  selectedDistributions,
  counter,
  updateTools,
  watcher,
  table,
  disableAdd,
  reject_reason,
}) => {
  const isOnlyOneSelected = selectedDistributions.length === 1;

  const modifyContributionStatus = async (payload) => {
    const loggedInProfile = JSON.parse(
      localStorage.getItem("apn-ambassador-profile")
    );
    payload.input.status_modified_by = loggedInProfile.email;
    var todayDate = new Date();
    payload.input.status_modified_date = todayDate.toISOString().split("T")[0];

    await API.graphql(graphqlOperation(updateAPNContribution, payload))
      .then((res) => {
        console.log(JSON.stringify(res));

        if (res.data.updateAPNContribution != null) {
          console.log("contribution successfully updated: ", res);
          // create a flash message.
          // set the status on screen

          table.props.distributions.find(
            (x) => x.id === selectedDistributions[0].id
          ).status = res.data.updateAPNContribution.status;
          table.forceUpdate();
          var newFlash = (
            <Flash
              dismissible={true}
              type="success"
              visible={true}
              content={
                "Status modified successfully to " +
                res.data.updateAPNContribution.status
              }
            />
          );
          watcher.setFlashMessage(newFlash);
        } else {
          watcher.setFlashMessage(
            <Flash
              dismissible={true}
              type="error"
              visible={true}
              content={"Something went wrong. Try again later."}
            />
          );
        }
      })
      .catch((error) =>
        console.log("Something went wrong updating contribution: ", error)
      );
  };

  if (
    reject_reason !== undefined &&
    reject_reason !== "" &&
    table.state.cancelReject !== true
  ) {
    console.log("Reason for rejection is: " + reject_reason);
    // do the rejection
    console.log("Doing one rejection");

    var payload = {
      input: {
        id: selectedDistributions[0].id,
        status: "REJECTED",
        reject_reason: reject_reason,
      },
    };

    console.log(payload);

    modifyContributionStatus(payload);

    table.setState({ reject_reason: "" });
  }

  function handleReject(e) {
    e.preventDefault();
    table.setState({
      showRejectModal: true,
      cancelReject: false,
      reject_reason: "",
    });
  }

  function modifyStatus(e, action) {
    e.preventDefault();
    // console.log("modifyStatus");
    // console.log(e);
    // console.log(action);

    // console.log(selectedDistributions[0].status);

    var payload = {
      input: {
        id: selectedDistributions[0].id,
        status: action,
      },
    };

    console.log(payload);

    modifyContributionStatus(payload);
  }

  // eslint-disable-next-line
  function handleDelete(e) {
    e.preventDefault();
    console.log("The link was clicked.");
    this.setState({ showDeleteModal: true });
    table.deleteSelections();
  }

  return (
    <div className="awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-title">
        <h1 className="awsui-util-font-size-3 awsui-util-d-ib">
          <strong>
            All Ambassador Contributions (Region:
            {watcher.state.loading === true ? (
              <Spinner></Spinner>
            ) : (
              watcher.state.regionFilter
            )}
            )
          </strong>
        </h1>
      </div>
      <div className="awsui-util-action-stripe-group">
        {/*
              <Button text='Delete' disabled={selectedDistributions.length === 0} onClick={handleDelete}/>
                */}
        <Button variant="link" text="Modify Status:" />
        <Button
          disabled={
            !isOnlyOneSelected || selectedDistributions[0].status === "APPROVED"
          }
          text="APPROVED"
          onClick={(e) => {
            modifyStatus(e, "APPROVED");
          }}
        />
        <Button
          disabled={
            !isOnlyOneSelected || selectedDistributions[0].status === "REJECTED"
          }
          text="REJECTED"
          onClick={(e) => {
            handleReject(e);
          }}
        />
        <Button
          disabled={
            !isOnlyOneSelected || selectedDistributions[0].status === "PENDING"
          }
          text="PENDING"
          onClick={(e) => {
            modifyStatus(e, "PENDING");
          }}
        />
        <Button
          disabled={
            !isOnlyOneSelected ||
            selectedDistributions[0].status === "REVIEWING"
          }
          text="REVIEWING"
          onClick={(e) => {
            modifyStatus(e, "REVIEWING");
          }}
        />
      </div>
    </div>
  );
};

class ContributionsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefinitions: COLUMN_DEFINITIONS,
      selectedDistributions: [],
      filteringText: "",
      pageSize: 50,
      contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
      wrapLines: false,
      isAdmin: false,
      selectedYear: new Date().getFullYear().toString(),
      selectedStatus: "PENDING",
    };
    this.onPaginationChange = this.onPaginationChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    loadWidths().then((columnDefinitions) => {
      this.setState({
        columnDefinitions,
      });
    });
  }

  onFilteringChange({ detail: { filteringText } }) {
    this.setState({
      filteringText,
    });
  }

  onPaginationChange({ detail: { pageSize } }) {
    this.setState({
      pageSize,
    });
  }

  onContentSelectionChange({ detail }) {
    const contentSelection = detail.contentSelection;
    const currentContentSelectorOptionGroup =
      this.state.contentSelectorOptions[0];
    this.setState({
      contentSelectorOptions: [
        {
          label: currentContentSelectorOptionGroup.label,
          options: currentContentSelectorOptionGroup.options.map((opt) => ({
            id: opt.id,
            label: opt.label,
            editable: opt.editable,
            visible: contentSelection.indexOf(opt.id) !== -1,
          })),
        },
      ],
    });
  }

  onWrapLinesChange({ detail }) {
    this.setState({
      wrapLines: detail.value,
    });
  }

  headerCounter(selectedDistributions, distributions) {
    return selectedDistributions.length
      ? `(${selectedDistributions.length}/${distributions.length})`
      : `(${distributions.length})`;
  }

  clearFilter() {
    this.setState({
      filteringText: "",
    });
  }

  deleteSelections() {
    // alert(this.state.selectedDistributions);
  }

  handleChange(e, target) {
    e.preventDefault();
    console.log("Handle change:" + target);
    //console.log(e);
    if (e.detail.value !== undefined) {
      if (target === "reject_reason")
        this.setState({ reject_reason: e.detail.value });
    }
  }

  handleSelectYear(e) {
    e.preventDefault();
    console.log(e);
    this.setState({ selectedYear: e.detail.selectedId });
    console.log(this.props.watcher);
    this.props.watcher.changeYear(e.detail.selectedId);
  }

  handleSelectStatus(e) {
    e.preventDefault();
    console.log(e);
    this.setState({ selectedStatus: e.detail.selectedId });
    console.log(this.props.watcher);
    this.props.watcher.changeStatusFilter(e.detail.selectedId);
  }

  handleSelectRegion(e) {
    e.preventDefault();
    console.log(e);
    this.setState({ selectedRegion: e.detail.selectedId });
    console.log(this.props.watcher);
    this.props.watcher.changeRegionFilter(e.detail.selectedId);
  }

  handleOk(e) {
    e.preventDefault();

    this.setState({ showRejectModal: false, cancelReject: false });
    // alert(this.state.reject_reason);
  }

  handleCancel(e) {
    e.preventDefault();
    this.props.watcher.setFlashMessage(null);

    this.setState({
      cancelReject: true,
      reject_reason: "",
      showRejectModal: false,
    });
  }

  render() {
    return (
      <div>
        &nbsp;&nbsp;
        <Link to={"/viewProfile/" + this.props.currentUser.sub}>
          <Button text="View My Profile" />
        </Link>
        &nbsp; &nbsp;
        <div style={{ float: "left", marginTop: "5px" }}>
          &nbsp;<b>Status: </b>&nbsp;
        </div>
        <div style={{ float: "left" }}>
          <Select
            name="selectedStatus"
            options={statusOptions}
            selectedId={this.state.selectedStatus}
            onChange={(e) => {
              this.handleSelectStatus(e);
            }}
          ></Select>
          &nbsp;&nbsp;
        </div>
        <div style={{ float: "left", marginTop: "5px" }}>
          &nbsp;&nbsp;
          <b>Year: </b>&nbsp;
        </div>
        <div style={{ float: "left" }}>
          <Select
            name="year"
            options={yearOptions}
            selectedId={this.state.selectedYear}
            onChange={(e) => {
              this.handleSelectYear(e);
            }}
          ></Select>
          &nbsp;&nbsp;
        </div>
        <div style={{ float: "left", marginTop: "5px" }}>
          &nbsp; <b>Region: </b>&nbsp;
        </div>
        <div style={{ float: "left" }}>
          <Select
            name="selectedRegion"
            options={adminRegionOptions}
            selectedId={this.state.selectedRegion}
            onChange={(e) => {
              this.handleSelectRegion(e);
            }}
          ></Select>
          &nbsp;&nbsp;
        </div>
        &nbsp;
        <span style={floatRightStyle}>
          <b>Administration:</b> &nbsp;
          <Link to="/profiles">
            <Button variant="primary" text="Profiles" />
          </Link>
          &nbsp;
          <Link to="/achievementsAdmin">
            <Button disabled={true} variant="primary" text="Contributions" />
          </Link>
          &nbsp;
          <Link to="/reviewGuide">
            <Button disabled={false} variant="primary" text="Review Guide" />
          </Link>
          &nbsp;
        </span>
        <p />
        <Modal
          visible={this.state.showRejectModal === true}
          header="Rejection Reason"
          footer={
            <span className="awsui-util-f-r">
              <Button
                variant="link"
                text="Cancel"
                onClick={(e) => {
                  this.handleCancel(e);
                }}
              ></Button>
              <Button
                variant="primary"
                text="Ok"
                onClick={(e) => {
                  this.handleOk(e);
                }}
              ></Button>
            </span>
          }
        >
          <span>
            <h5>Enter a reason for rejection here.</h5>
            <Textarea
              rows={3}
              name="reject_reason"
              value={this.state.reject_reason}
              onChange={(e) => {
                this.handleChange(e, "reject_reason");
              }}
            ></Textarea>
          </span>
        </Modal>
        <Table
          columnDefinitions={this.state.columnDefinitions}
          items={this.props.distributions}
          wrapLines={true}
          stickyHeader={true}
          resizableColumns={true}
          onColumnWidthsChange={saveWidths}
          header={
            <TableHeader
              selectedDistributions={this.state.selectedDistributions}
              counter={this.headerCounter(
                this.state.selectedDistributions,
                this.props.distributions
              )}
              watcher={this.props.watcher}
              table={this}
              disableAdd={this.props.disableAdd}
              reject_reason={this.state.reject_reason}
            />
          }
          onWrapLinesChange={this.onWrapLinesChange.bind(this)}
        >
          <TableFiltering
            filteringLabel="Search Achievements"
            filteringPlaceholder="Search Achievements"
            filteringText={this.state.filteringText}
            onFilteringChange={this.onFilteringChange.bind(this)}
            filteringCountTextFunction={getFilterCounterText}
          />
          <TablePagination
            onPaginationChange={this.onPaginationChange}
            labels={paginationLabels}
            pageSize={this.state.pageSize}
          />
          <TableSorting
            sortableColumns={SORTABLE_COLUMNS}
            sortingColumn={"created"}
            sortingDescending={false}
          />
          <TableSelection
            selectionType={"single"}
            selectedItems={this.state.selectedDistributions}
            labels={distributionSelectionLabels}
            onSelectionChange={(evt) =>
              this.setState({ selectedDistributions: evt.detail.selectedItems })
            }
          />
        </Table>
      </div>
    );
  }
}

export default ContributionsList;
