import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom'

import './styles/table.scss';
import {
    Button
  } from '@amzn/awsui-components-react';
import Markdown from 'markdown-to-jsx'


import { Storage } from 'aws-amplify' 

const ContributionGuide = () =>{

    const [markdown, setMarkdown] = useState("Loading");

    const fetchMarkdown = async ()=>{
        const result = await Storage.get(`ContributionsGuide.md`, { download: true });
        console.log(result);
        let text = await result.Body.text();
        console.log(text)
        setMarkdown(text)
    }



  useEffect(() => {
    fetchMarkdown();
  });

    return (
        <div style={{marginLeft: 50}}>
        <span>
              <Link to={"/contributions"}><Button text='Back'/></Link>
                <div style={{height: '80vh', overflowY: 'auto', padding: '20px'}}>
                  <Markdown>{markdown}</Markdown>
                </div>
        </span>
        </div>
    )
}

export default ContributionGuide;
