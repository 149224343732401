import React from 'react';

export function findContributionById(find){
    var allTypes=commonContributionTypes.concat(ISVTypes).concat(consultingTypes).concat(technologyTypes);
    return allTypes.filter(x => x.id === find );
  }

export const findCommunityContributionType = (find) =>{return commonContributionTypes.filter(x => x.id === find );}

export const getContributionTypes = (partner_type, program_status) =>{

    var types = commonContributionTypes;


    if (program_status!=="SUSPENDED"){

        if (partner_type==="Software"){
            types = types.concat(ISVTypes);
        }
        if (partner_type==="Services"){
            types = types.concat(consultingTypes);
        }
        if (partner_type==="Technology"){
            types = types.concat(technologyTypes);
        }
    
        if (program_status!==undefined && program_status!==null){
    
            if (program_status==="CANDIDATE"){
                types = types.filter(function( obj ) {
                    return obj.label !== 'AWS Community Event';
                });
            }
        }

    } // END IF SUSPENDED


    return types.sort((a, b) => (a.label > b.label) ? 1 : -1)
}

export const consultingTypes = [
    {
        label: "AWS Case Study",
        id: "AWS Case Study",
        score: 30,
        link: true,
        desc: <div>Case study published on the AWS case study site. </div>
    },
    {
        label: "APN Competency",
        id: "APN Competency",
        score: 40,
        link: true,
        desc: <div>Attainment of an APN Competency by your organization.</div>,
    },
    {
        label: "APN Customer Engagements - Opportunity",
        id: "APN Customer Engagements - Opportunity",
        score: 40,
        link: false,
        desc: <div>Influenced an ACE opportunity.</div>
    },
    {
        label: "APN Immersion Days - Delivery",
        id: "APN Immersion Days - Delivery",
        score: 5,
        link: false,
        desc: <div>Delivery of an APN Immersion Day to multiple customers. </div>
    },
    {
        label: "APN Immersion Days - Program",
        id: "APN Immersion Days - Program",
        score: 30,
        link: true,
        desc: <div>Partner organization joined the APN Immersion Days program. </div>
    },
    {
        label: "AWS Managed Service Provider - Program",
        id: "AWS Managed Service Provider - Program",
        score: 40,
        link: false,
        desc: <div>Partner organization achieved or renewed MSP status.</div>
    },
    {
        label: "APN Service Delivery",
        id: "APN Service Delivery",
        score: 40,
        link: true,
        desc: <div>AWS Service Delivery Attainment of an AWS Service Validation by your organization. </div>
    },
    /*
    {
        label: "APN Solution Space",
        id: "APN Solution Space",
        score: 40,
        link: true,
        desc: <div>APN Solution Space Offering of an APN Solution Space Consulting Offer by your organization. The link must point to the consulting offer <a href="https://aws.amazon.com/solutions/consulting-offers"  target="_blank" rel="noopener noreferrer">https://aws.amazon.com/solutions/consulting-offers</a></div>
    },*/
    // {
    //     label: "AWS Exam SME Contribution",
    //     id: "AWS Exam SME Contribution",
    //     score: 40,
    //     link: false,
    //     desc: <div>Contributed to the AWS Certification exam development. </div>
    // },
    {
        label: "Cert Influence - Associate",
        id: "Cert Influence - Associate",
        score: 5,
        link: true,
        desc: <div>Influenced the attainment of an AWS Associate certification. </div>,
        description_required: false,
    },
    {
        label: "Cert Influence - Practitioner",
        id: "Cert Influence - Practitioner",
        score: 5,
        link: true,
        desc: <div>Influenced the attainment of an AWS Cloud Practitioner certification. </div>,
        description_required: false,
    },
    {
        label: "Cert Influence - Professional",
        id: "Cert Influence - Professional",
        score: 10,
        link: true,
        desc: <div>Influenced the attainment of an AWS Professional certification. </div>,
        description_required: false,
    },
    {
        label: "Cert Influence - Specialty",
        id: "Cert Influence - Specialty",
        score: 15,
        link: true,
        desc: <div>Influenced the attainment of an AWS Specialty certification. </div>,
        description_required: false,
    },
    {
        label: "AWS Well-Architected Partner - Program",
        id: "AWS Well-Architected Partner - Program",
        score: 30,
        link: true,
        desc: <div>Partner organization joined the AWS Well-Architected Partner Program. </div>
    },
    {
        label: "AWS Well-Architected Partner - Review",
        id: "AWS Well-Architected Partner - Review",
        score: 5,
        link: false,
        desc: <div>Conducted a Well-Architected Review as practitioner under the APN Well-Architected Partner Program. </div>
    },
    {
        label: "AWS Partner Event",
        id: "AWS Partner Event",
        score: 40,
        link: true,
        desc: <div>Partner organization hosted an AWS event. </div>
    },
    {
        label: "AWS Partner Solution",
        id: "AWS Partner Solution",
        score: 40,
        link: true,
        desc: <div>Consulting offering, industry solution or product. </div>
    },
    {
        label: "AWS Service Ready",
        id: "AWS Service Ready",
        score: 40,
        link: true,
        desc: <div>Partner organization attained a AWS Service Ready designation. </div>
    },
    {
        label: "AWS Summit",
        id: "AWS Summit",
        score: 15,
        link: true,
        desc: <div>Activity supporting specific AWS global events. </div>
    },
]


export const ISVTypes = [
    {
        label: "APN Customer Engagements - Opportunity",
        id: "APN Customer Engagements - Opportunity",
        score: 40,
        link: false,
        desc: <div>Influenced an ACE opportunity.</div>
    },
    {
        label: "AWS Case Study",
        id: "AWS Case Study",
        score: 30,
        link: true,
        desc: <div>Case study published on the AWS case study site. </div>
    },
    {
        label: "Cert Influence - Associate",
        id: "Cert Influence - Associate",
        score: 5,
        link: true,
        desc: <div>Influenced the attainment of an AWS Associate certification. </div>,
        description_required: false,
    },
    {
        label: "Cert Influence - Practitioner",
        id: "Cert Influence - Practitioner",
        score: 5,
        link: true,
        desc: <div>Influenced the attainment of an AWS cloud practitioner certification. </div>,
        description_required: false,
    },
    {
        label: "Cert Influence - Professional",
        id: "Cert Influence - Professional",
        score: 10,
        link: true,
        desc: <div>Influenced the attainment of an AWS Professional certification. </div>,
        description_required: false,
    },
    {
        label: "Cert Influence - Specialty",
        id: "Cert Influence - Specialty",
        score: 15,
        link: true,
        desc: <div>Influenced the attainment of an AWS Specialty certification. </div>,
        description_required: false,
    },
    /*
    {
        label: "AWS Quick Start",
        id: "AWS Quick Start",
        score: 40,
        link: true,
        desc: <div>Newly published AWS Quick Start Offering for Partner Solution. <a href="https://aws.amazon.com/quickstart/"  target="_blank" rel="noopener noreferrer">https://aws.amazon.com/quickstart/</a></div>
    },
    {
        label: "Marquee Design Win",
        id: "Marquee Design Win",
        score: 40,
        link: true,
        desc: <div>Launch and availability of a new ISV solution/product on AWS, or a significant re-architecture of an existing solution/product using AWS. MDW should also be announced publicly with a Press Release from the partner or via a blog post. Ambassador must be able to provide documentation of involvement in Design Win. The link must point to a press release, release notes or other public facing announcement on the partner's website.</div>
    }*/
    {
        label: "AWS Partner Event",
        id: "AWS Partner Event",
        score: 40,
        link: true,
        desc: <div>Partner organization hosted an AWS event. </div>
    },
    {
        label: "AWS Partner Solution",
        id: "AWS Partner Solution",
        score: 40,
        link: true,
        desc: <div>Consulting offering, industry solution or product. </div>
    },
    {
        label: "AWS Service Ready",
        id: "AWS Service Ready",
        score: 40,
        link: true,
        desc: <div>Partner organization attained a AWS Service Ready designation. </div>
    },
    {
        label: "AWS Summit",
        id: "AWS Summit",
        score: 15,
        link: true,
        desc: <div>Activity supporting specific AWS global events. </div>
    },
]

export const technologyTypes = [
    {
        label: "AWS Case Study",
        id: "AWS Case Study",
        score: 30,
        link: true,
        desc: <div>Case study published on the AWS case study site. </div>
    }
]

export const commonContributionTypes =   [
    {
        label: "Article",
        id: "Article",
        score: 20,
        link: true,
        desc: <div>Online publication at a publisher, magazine, trade journal or similar. </div>
    },
    {
        label: "Book",
        id: "Book",
        score: 40,
        link: true,
        desc: <div>Publication of a book at Amazon covering the AWS cloud or it's services in detail. </div>
    },/*
    {
        label: "AWS Community Event",
        id: "AWS Community Event",
        score: 20,
        link: true,
        desc: <div>Supporting an AWS-hosted community event.</div>
    },*/
    {
        label: "Blog Post",
        id: "Blog Post",
        score: 30,
        link: true,
        desc: <div>Online technical publication on the AWS/APN blog or any other blog platform. </div>
    },
    /*
    {
        label: "Design Win",
        id: "Design Win",
        score: 20,
        link: true,
        desc: <div>Significant technical/business development work to integrate partner products and solutions with AWS services. Ambassador must be able to provide documentation of involvement in Design Win. The link must point to a press release, release notes or other public facing announcement on the partner's website. </div>
    },
    {
        label: "Event Hosting",
        id: "Event Hosting",
        score: 40,
        link: true,
        desc: <div>Hosting a User Group, community meeting, tech academy, re:Invent re:Cap, ... </div>
    },*/
    // {
    //     label: "Open Source Project",
    //     id: "Open Source Project",
    //     score: 40,
    //     link: true,
    //     desc: <div>Source code on open repositories like GitHub</div>
    // },
    {
        label: "Podcast",
        id: "Podcast",
        score: 10,
        link: true,
        desc: <div>Online digital audio recording. </div>
    },
    {
        label: "Presentation",
        id: "Presentation",
        score: 20,
        link: true,
        desc: <div>In-person presentation at a meetup, seminar, conference, etc. </div>
    },
    // {
    //     label: "User Group Meetup",
    //     id: "User Group Meetup",
    //     score: 40,
    //     link: true,
    //     desc: <div>AWS User Group meetup you organized as the owner/primary organizer of the AWS User Group.</div>
    // },
    {
        label: "Webinar",
        id: "Webinar",
        score: 10,
        link: true,
        desc: <div>Online video recording of a web-based video conference, digital/online conference, etc. </div>
    },
    {
        label: "Whitepaper",
        id: "Whitepaper",
        score: 40,
        link: true,
        desc: <div>Authoritative report or guide on a subject. </div>
    }
];


export const obsoleteTypes=[
    {
        label: "Competency",
        id: "Competency",
        score: 50,
        link: false
    },
    {
        label: "Documentation",
        id: "Documentation",
        score: 20,
        link: true
    },
    {
        label: "Event",
        id: "Event",
        score: 40,
        link: true
    }
]