import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";

import {
  listAPNContributions,
  getAPNAmbassadorProfile,
} from "./graphql/queries";

import * as JWT from "jwt-decode";

import "@amzn/awsui-components-react/index.css";
import "./styles/form.scss";
import { Auth } from "aws-amplify";

import { AppLayout } from "@amzn/awsui-components-react";
import { appLayoutLabels } from "./common/labels";
import "./styles/form.scss";

import AchievementsAdminList from "./AchievementsAdminList";
import ContributionsList from "./AchievementsAdminList";

class AchievementsAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentOrigins: [],
      toolsIndex: 0,
      toolsOpen: false,
      showCreateForm: false,
      currentUser: { email: "nobody", administratior: false },
      userProfile: {},
      contributions: [],
      flash_message: null,
      noprofile: true,
      loading: true,
      regionFilter: "GLOBAL",
      selectedStatus: "PENDING",
      //filter: {filter : {date_completed: {between:[new Date().getFullYear().toString()+"-01-01", new Date().getFullYear().toString()+"-12-31"]}}},
      filter: {
        filter: { createdAt: { between: ["2019-01-01", "2019-12-31"] } },
      },
      selectedYear: new Date().getFullYear().toString(),
    };
  }

  componentWillMount() {
    ("will mount");

    let parent = this;

    Auth.currentAuthenticatedUser()
      .then((user) => {
        // console.log(user);
        var token = JWT(user.signInUserSession.accessToken.jwtToken);

        if (
          token["cognito:groups"] !== undefined &&
          token["cognito:groups"][0] === "APNAmbassadorsAdministrators"
        ) {
          user.attributes.administrator = true;
          parent.setState({ currentUser: user.attributes });

          API.graphql(
            graphqlOperation(getAPNAmbassadorProfile, {
              cognito_user: user.attributes.sub,
            })
          )
            .then((res) => {
              // console.log("Ambassador res ", res )

              // filter the region for this ambassador
              var filter = {}; // empty for global
              var regionFilter = "GLOBAL"; // empty for global

              if (
                res.data.getAPNAmbassadorProfile.admin_region !== null &&
                res.data.getAPNAmbassadorProfile.admin_region !== "" &&
                res.data.getAPNAmbassadorProfile.admin_region !== "GLOBAL"
              ) {
                filter = {
                  filter: {
                    region: {
                      eq: res.data.getAPNAmbassadorProfile.admin_region,
                    },
                  },
                };
                regionFilter = res.data.getAPNAmbassadorProfile.admin_region;
              }

              this.setState({ filter: filter, regionFilter: regionFilter });

              this.recursiveLoadRegionalAchievements();
            })
            .catch((error) => {
              this.recursiveLoadRegionalAchievements();
            });
        } else {
          console.log("Not an administrator, rediecting...");
          this.props.history.push({
            pathname: "/contributions",
          });
        }
      })
      .catch((err) => console.log(err)); // end authentication
  } // end will mount

  async recursiveLoadRegionalAchievements() {
    const fetchPages = async (nextToken, params) => {
      // console.log("Sending: " + nextToken);
      //  console.log(params);

      if (nextToken !== "") {
        params.nextToken = nextToken;
      }

      await API.graphql(graphqlOperation(listAPNContributions, params))
        .then((res) => {
          // console.log("Something went right: ", res )

          fetchedContributions = fetchedContributions.concat(
            res.data.listAPNContributions.items
          );
          //(fetchedContributions.length);

          //  console.log("nextToken: " + res.data.listAPNContributions.nextToken);

          if (null !== res.data.listAPNContributions.nextToken) {
            fetchPages(res.data.listAPNContributions.nextToken, params);
          } else {
            console.log("Token empty.");
            //  console.log("Found: " + fetchedContributions.length);

            // do a trick and add the ambassador name to the top level object
            /*
        for (let i=0;i<fetchedContributions.length;i++){
          fetchedContributions[i].full_name = fetchedContributions[i].ambassador.first_name + " " + fetchedContributions[i].ambassador.last_name
        }*/

            this.setState({
              contributions: fetchedContributions,
              loading: false,
            });
          }
        })
        .catch((error) => console.log("Something went wrong: ", error));
    };
    //console.log(filter);
    //console.log(regionFilter);
    //filter.limit=300;

    let fetchedContributions = [];
    this.setState({ loading: true });
    var filter = {};
    filter.filter = {};
    if (this.state.selectedYear !== "ALL")
      filter.filter = {
        createdAt: {
          between: [
            this.state.selectedYear + "-01-01",
            parseInt(this.state.selectedYear) + 1 + "-01-01",
          ],
        },
      };

    if (this.state.selectedStatus !== "ALL")
      filter.filter.status = { eq: this.state.selectedStatus };

    console.log(this.state.regionFilter);
    if (this.state.regionFilter !== "GLOBAL")
      filter.filter.region = { eq: this.state.regionFilter };

    filter.limit = 10000;
    if (
      this.state.selectedYear === "ALL" &&
      this.state.selectedStatus === "ALL" &&
      this.state.regionFilter === "GLOBAL"
    ) {
      var nofilter = {};
      nofilter.limit = 10000;
      await fetchPages("", nofilter);
    } else {
      await fetchPages("", filter);
    }
  }

  setFlashMessage(flash) {
    this.setState({ flash_message: flash });
  }

  changeYear(year) {
    //console.log(year);
    this.setState({ selectedYear: year });
    this.recursiveLoadRegionalAchievements();
  }

  changeStatusFilter(status) {
    //console.log(status);
    this.setState({ selectedStatus: status });
    this.recursiveLoadRegionalAchievements();
  }

  changeRegionFilter(region) {
    //console.log(status);
    this.setState({ regionFilter: region });
    this.recursiveLoadRegionalAchievements();
  }

  render() {
    return (
      <div class="awsui">
        <AppLayout
          contentType="table"
          notifications={this.state.flash_message}
          content={
            <AchievementsAdminList
              watcher={this}
              disableAdd={this.state.noprofile}
              isAdmin={this.state.currentUser.administrator}
              loading={this.state.loading}
              distributions={this.state.contributions}
              currentUser={this.state.currentUser}
              updateTools={() => this.setState({ toolsOpen: true })}
            />
          }
          navigationHide
          toolsHide
          labels={appLayoutLabels}
        />
      </div>
    );
  }
}

export default AchievementsAdmin;
